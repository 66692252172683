<template>
  <div>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Canais de Divulgação</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el label=" Meus Canais" exact />
      </q-breadcrumbs>
    </q-toolbar>

    <q-separator spaced />

    <q-banner class="bg-cyan-2 text-info q-my-md" rounded>
      <q-item>
        <q-item-section top avatar>
          <q-icon name="mdi-information-outline" size="lg" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="text-black">
            <strong>O que é um canal de divulgação?</strong><br />

            São sites ou páginas online específicas usadas para anunciar e divulgar produtos e serviços da marca,
            levando-os ao maior número de pessoas possível por meio de links gerados em nossa plataforma. Você pode ter
            vários canais e diferentes métodos de divulgação. Atenção: Após cadastrar o seu canal, ele ficará com o
            status de pendente. Nosso time irá analisar se ele está dentro dos nossos requisitos e dará um retorno em
            até
            <strong>72h</strong>!
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-banner>

    <q-banner v-if="this.$moment().format('YYYY-MM-DD') < '2023-01-05'" class="text-negative q-my-md" rounded>
      <q-item>
        <q-item-section top avatar>
          <q-icon name="mdi-bullhorn-outline" size="lg" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="text-black">
            <strong
              >Devido ao recesso de Final de Ano entre os dias 23/12 e 05/01, nosso prazo para aprovação de Canais pode
              ser maior que o período padrão de 72h.</strong
            >
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-banner>

    <div class="row">
      <q-space />
      <q-btn
        v-if="viewAdd"
        color="primary"
        icon="mdi-plus"
        label="Adicionar"
        no-caps
        unelevated
        @click="ActionSetDialogChannel(true)"
      >
      </q-btn>
    </div>
  </div>
</template>

<script>
import ChannelMixin from '../../mixins/ChannelMixin';
import affiliateService from '../../services/AffiliateService';
export default {
  name: 'HeaderMyChannels',
  mixins: [ChannelMixin, affiliateService],

  /* data() {
        return {
        };
    }, */

  props: {
    viewAdd: {
      type: Boolean,
      default: false
    }
  }
};
</script>

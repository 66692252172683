<template>
  <q-dialog v-model="dialogChannel" persistent @show="setValue()">
    <q-card>
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title>
          {{ channelSelect ? 'Edição' : 'Cadastro' }} de Canal
          {{ channelId ? `(${channelId})` : '' }}
        </q-toolbar-title>
        <q-btn flat icon="mdi-close" @click="onClose()" round dense />
      </q-toolbar>
      <q-form @submit="onEdit">
        <q-card-section class="row q-col-gutter-md">
          <div class="col-12">
            <q-input outlined label="Nome do Site" type="text" v-model="params.name" lazy-rules :rules="[isRequired]" />
          </div>
          <div class="col-12">
            <q-select
              v-model="params.channelType"
              use-input
              input-debounce="200"
              @filter="filterTypeChannels"
              :options="typeChannelsFiltered"
              label="Tipo de Canal"
              outlined
              option-label="site_type"
              option-value="id"
              clear-icon="mdi-close"
              clearable
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
          <div class="col-12">
            <q-select
              v-model="params.category"
              use-input
              input-debounce="200"
              @filter="filterCategories"
              :options="categoriesFiltered"
              :label="$t('category')"
              outlined
              option-label="name"
              option-value="id"
              clear-icon="mdi-close"
              clearable
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
          <div class="col-12">
            <q-input
              outlined
              label="URL"
              type="text"
              v-model="params.url"
              lazy-rules
              :rules="[validURL]"
              error-message="Verifique a URL"
            />
          </div>
          <div class="col-12">
            <q-input
              outlined
              v-model="params.description"
              type="textarea"
              label="Descrição"
              counter
              maxlength="500"
              placeholder="Você tem 500 caracteres para descrever seu canal."
              lazy-rules
              :rules="[isRequired]"
            />
          </div>

          <div class="col-12 text-right">
            <q-btn color="positive" label="Salvar Alterações" no-caps unelevated type="submit" class="q-px-md" />
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import ChannelMixin from '../../mixins/ChannelMixin';
import AffiliateService from '../../services/AffiliateService';

export default {
  name: 'FormMyChannels',
  mixins: [ChannelMixin, AffiliateService],
  data() {
    return {
      categories: [],
      categoriesFiltered: [],
      typeChannels: [],
      typeChannelsFiltered: [],
      params: {
        id: null,
        category: null,
        channelType: null,
        description: null,
        name: null,
        url: null
      },
      channelId: null
    };
  },
  methods: {
    filterCategories(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.categoriesFiltered = this.categories;
        this.categoriesFiltered = this.categories.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterTypeChannels(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.typeChannelsFiltered = this.typeChannels;
        this.typeChannelsFiltered = this.typeChannels.filter(
          (v) => v.site_type.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    },

    onClose() {
      this.ActionSetDialogChannel(false);
      this.ActionSetChannelSelect({});
    },

    async getTypeChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/v2/redirect?app=AFFILIATE&path=/api/get/affiliate/list_site_type'
        );
        if (status === 200) this.typeChannels = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCategories() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          'v2/redirect?app=AFFILIATE&path=/api/get/affiliate/list_site_categories'
        );
        if (status === 200) this.categories = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    setValue() {
      if (this.channelSelect) {
        const { name, url, description, site_type, category } = this.channelSelect;
        this.params = {
          name,
          url,
          description,
          channelType: this.typeChannels.find((item) => item.site_type === site_type),
          category: this.categories.find((item) => item.name === category)
        };
      } else {
        this.params = {
          name: null,
          url: null,
          description: null,
          channelType: null,
          category: null
        };
      }
    },

    urlHttp(urlInput) {
      if (urlInput) {
        let https = urlInput.slice(0, 8).toLowerCase();
        let http = urlInput.slice(0, 7).toLowerCase();
        if (https === 'https://') return urlInput;
        else if (http === 'http://') return urlInput;
        else return 'http://' + urlInput;
      } else return '';
    },

    onEdit() {
      if (this.channelId !== null) {
        let paramsEdit = {
          name: this.params.name,
          description: this.params.description,
          url: this.params.url,
          site_type_id: this.params.channelType.id,
          category: this.params.category.id
        };

        return this.editChannel(paramsEdit);
      }

      let paramsEdit = {
        name: this.params.name,
        description: this.params.description,
        url: this.urlHttp(this.params.url),
        site_type_id: this.params.channelType.id,
        category: this.params.category.id,
        affiliate_id: this.user.affiliate.id
      };

      return this.createChannel(paramsEdit);
    },

    async editChannel(paramsEdit) {
      this.onLoading(true);
      try {
        const { data, status } = await this.editAffiliateChannel(this.channelSelect.id, paramsEdit);
        if (status === 200 || status === 201) {
          this.getMyChannels();
          this.successNotify(data.message);
          this.onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async createChannel(paramsEdit) {
      this.onLoading(true);
      try {
        const { status } = await this.createAffiliateChannel(paramsEdit);
        if (status === 200 || status === 201) {
          this.getMyChannels();
          this.successNotify('Canal criado com sucesso');
          this.onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.getTypeChannels();
    this.getCategories();
  },
  watch: {
    channelSelect() {
      if (this.channelSelect !== undefined && this.channelSelect !== null) {
        this.channelId = this.channelSelect.id;
      } else {
        this.channelId = null;
      }
    }
  }
};
</script>

<template>
  <q-page padding>
    <header-my-channels :viewAdd="viewAdd" />
    <list-my-channels :viewAdd="viewAdd" @viewAddTrue="viewAdd = true" />
    <form-my-channels />
  </q-page>
</template>

<script>
import ListMyChannels from '../../components/channel/ListMyChannels.vue';
import HeaderMyChannels from '../../components/channel/HeaderMyChannels.vue';
import FormMyChannels from '../../components/channel/FormMyChannels.vue';
import affiliateService from '../../services/AffiliateService';
export default {
  components: { HeaderMyChannels, ListMyChannels, FormMyChannels },
  mixins: [affiliateService],
  name: 'PageMyChannels',

  data() {
    return {
      viewAdd: false
    };
  },

  created() {
    this.getAffiliatePayInfo();
  },

  methods: {
    async getAffiliatePayInfo() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateDataPayment();
        if (status === 200) {
          if (data.length) this.viewAdd = true;
          else this.viewAdd = false;
        }
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
